<template>
  <span class="d-block">
    <strong>Data:</strong> {{ date }} <br />
    (Horário de Brasília)

  </span>
</template>

<script>
// eslint-disable-next-line
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'

export default {
  name: 'Date',
  data: () => ({
    date: ''
  }),

  mounted () {
    this.date = moment(this.getDate).format('DD/MM/YYYY HH:mm:ss')

    // setInterval(() => {
    //   this.date = moment(this.getDate).format('DD/MM/YYYY HH:mm:ss')
    // }, 60000)
  },

  methods: {
    ...mapActions('dateServer', ['setDate'])
  },
  
  components: {
    ...mapGetters('dateServer', ['getDate'])
  }
}
</script>