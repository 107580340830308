export default {
  // validações dados ats instituição
  nameErrors () {
    const errors = []
    if (!this.$v.item.name.$dirty) return errors
    !this.$v.item.name.required && errors.push('O nome é obrigatório.')
    return errors
  },

  produtoIdErrors () {
    const errors = []
    if (!this.$v.item.productId.$dirty) return errors
    !this.$v.item.productId.required && errors.push('O nome é obrigatório.')
    return errors
  }
}
